define("ember-query-params-service/utils/query-param/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ensureService = ensureService;
  _exports.extractArgs = extractArgs;
  _exports.getQPService = getQPService;
  _exports.tryDeserialize = tryDeserialize;
  _exports.trySerialize = trySerialize;
  function extractArgs(args, propertyKey) {
    const [maybePathMaybeOptions, maybeOptions] = args;
    let propertyPath;
    let options;
    if (typeof maybePathMaybeOptions === 'string') {
      propertyPath = `current.${maybePathMaybeOptions}`;
      options = maybeOptions || {};
    } else {
      propertyPath = `current.${propertyKey}`;
      options = maybePathMaybeOptions || {};
    }
    return [propertyPath, options];
  }
  function tryDeserialize(value, options) {
    var _value;
    value = (_value = value) !== null && _value !== void 0 ? _value : options.defaultValue;
    if (!options.deserialize) return value;
    return options.deserialize(value);
  }
  function trySerialize(value, options) {
    if (value === options.defaultValue) {
      value = undefined;
    }
    if (!options.serialize) return value;
    return options.serialize(value);
  }

  // can't cache the service in module space because we run in to race  conditions
  // where a service on an old app instance may still exist, but be tied to the
  // old application istead of the current one (such as in tests)
  const serviceCache = new WeakMap();
  function ensureService(context) {
    let service = serviceCache.get(context);
    if (!service) {
      service = getQPService(context);
      serviceCache.set(context, service);
    }
    return service;
  }
  function getQPService(context) {
    return Ember.getOwner(context).lookup('service:queryParams');
  }
});