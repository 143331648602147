define('ember-chrome-devtools/formatters/object', ['exports', 'ember-chrome-devtools/formatters/utils/jsonml'], function (exports, _jsonml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ObjectFormatter = undefined;
  exports.property = property;
  exports.properties = properties;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /** All properties from the object and its prototype chain, including non-enumerables */
  function* getProperties(obj) {
    const seen = Object.create(null);

    do {
      for (const name of Object.getOwnPropertyNames(obj)) {
        if (!(name in seen)) {
          seen[name] = true;
          yield [name, Object.getOwnPropertyDescriptor(obj, name)];
        }
      }
    } while (obj = Object.getPrototypeOf(obj));
  }

  /** Used in tests */
  function lookupDescriptor(obj, key) {
    do {
      const descriptor = Object.getOwnPropertyDescriptor(obj, key);
      if (descriptor) {
        return descriptor;
      }
    } while (obj = Object.getPrototypeOf(obj));
  }

  /**
   * Compute JsonML for an object property
   * @param obj the object
   * @param key the property name
   * @param descriptor the property descriptor
   * @returns {*} JsonML
   */
  function property(obj, key, descriptor = lookupDescriptor(obj, key)) {
    Ember.assert('missing descriptor', !!descriptor);

    const enumerable = descriptor.enumerable;

    if (typeof descriptor.get === 'function') {
      if (descriptor.get.name === 'GETTER_FUNCTION' || descriptor.get.isInheritingGetter || key === 'isDestroyed' || key === 'isDestroying') {
        // Ember getter that's probably safe to evaluate
        const value = descriptor.get.call(obj);
        return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.reference(value));
      }

      // ES5 getter: forcing the property to compute might have a side effect
      return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.lazy(() => _jsonml.reference(Ember.get(obj, key))));
    }

    const value = descriptor.value;
    if (typeof value === 'function') {
      // ignore
      return;
    }

    if (value instanceof Ember.ComputedProperty) {
      // For convenience, show cached ComputedProperty without needing to expand it
      // Don't check the cache for AliasedProperty https://github.com/emberjs/ember.js/issues/15545
      const cached = Ember.cacheFor(obj, key);
      if (cached !== undefined) {
        // use the cached value
        return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.computedPropertyIcon(), _jsonml.reference(cached));
      }
    }

    if (value !== null && typeof value === 'object' && value.isDescriptor) {
      // ComputedProperty (not cached) or AliasedProperty
      // Create a lazy getter, because forcing the property to compute might have a side effect
      return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.computedPropertyIcon(), _jsonml.lazy(() => _jsonml.reference(Ember.get(obj, key))));
    }

    return _jsonml.item(_jsonml.name(key, enumerable), _jsonml.separator(), _jsonml.reference(value));
  }

  function* properties(obj) {
    for (const _ref of getProperties(obj)) {
      var _ref2 = _slicedToArray(_ref, 2);

      const key = _ref2[0];
      const descriptor = _ref2[1];

      const element = property(obj, key, descriptor);
      if (element) {
        yield element;
      }
    }
  }

  class ObjectFormatter {
    header(obj) {
      if (obj instanceof Ember.Object && !Ember.Array.detect(obj)) {
        return _jsonml.header(obj.toString());
      }
    }
    hasBody() {
      return true;
    }

    body(obj) {
      return _jsonml.list(...properties(obj));
    }
  }
  exports.ObjectFormatter = ObjectFormatter;
});