define('ember-inline-edit/components/ember-inline-editor', ['exports', 'ember-inline-edit/templates/components/ember-inline-editor'], function (exports, _emberInlineEditor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const isInputField = el => {
    const tagName = el.tagName;


    if (!tagName) {
      return false;
    }

    return ['input', 'textarea', 'select'].includes(tagName.toLowerCase());
  };

  exports.default = Ember.Component.extend({
    layout: _emberInlineEditor.default,
    classNameBindings: ['isVisible:is-visible:is-hidden'],

    textFields: ['search', 'url', 'text', 'phone', 'email', 'number'],
    textAreaFields: ['textarea'],

    didReceiveAttrs() {
      this._super(...arguments);
      Ember.run.scheduleOnce('afterRender', this.focusOnInput.bind(this));
    },

    focusOnInput() {
      const children = [...this.element.children];

      children.forEach(child => {
        if (isInputField(child)) child.focus();
      });
    },

    keyUp(ev) {
      const field = this.field,
            textAreaFields = this.textAreaFields;
      const keyCode = ev.keyCode;


      const isEnter = keyCode === 13;
      const isEsc = keyCode === 27;

      if (isEnter && !textAreaFields.includes(field)) {
        this['on-save']();
      } else if (isEsc) {
        this['on-cancel']();
      }
    }
  });
});